import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromApp from './app.reducer';
import * as fromRouter from '@ngrx/router-store';



export const getRouterState = createFeatureSelector<any>('router');

export const {
  selectCurrentRoute,   // select the current route
  selectQueryParams,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteParams,    // select the current route params
  selectRouteParam,     // factory function to select a route param
  selectRouteData,      // select the current route data
  selectUrl,            // select the current url
} = fromRouter.getSelectors(getRouterState);

export const getActiveRoute = createSelector(selectUrl, selectQueryParams, (url, queryParams) => {
  return { url, queryParams };
});

export const getAppState = createFeatureSelector<fromApp.State>('app');

export const getStateLoginErrorMessage = createSelector(getAppState, (state: fromApp.State) => state.stateLoginErrorMessage);

export const getStateAIRTABLETOKEN = createSelector(getAppState, (state: fromApp.State) => state.stateAIR_TABLE_TOKEN);

export const getStateListevenement = createSelector(getAppState, (state: fromApp.State) => state.stateListevenement);

export const getStateUsers2 = createSelector(getAppState, (state: fromApp.State) => state.stateUsers2);

export const getStateIntIDEvent = createSelector(getAppState, (state: fromApp.State) => state.stateIntIDEvent);

export const getStateAmoutofEvent = createSelector(getAppState, (state: fromApp.State) => state.stateAmoutofEvent);

export const getStateGetDataFromEventID = createSelector(getAppState, (state: fromApp.State) => state.stateGetDataFromEventID);

export const getStateTempSelectedIDEvent = createSelector(getAppState, (state: fromApp.State) => state.stateTempSelectedIDEvent);

export const getStateUsers = createSelector(getAppState, (state: fromApp.State) => state.stateUsers);

export const getStateDataFromEventIDAdresse = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_Adresse);

export const getStateDataFromEventIDTransversale = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_transversale);

export const getStateDataFromEventIDNatureincident = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_natureincident);

export const getStateDataFromEventIDHeureincident = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_heureincident);

export const getStateDataFromEventIDDateincident = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_dateincident);

export const getStateDataFromEventIDDeroulement = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_deroulement);

export const getStateDataFromEventIDIndividu1 = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_individu1);

export const getStateDataFromEventIDIndividu2 = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_individu2);

export const getStateDataFromEventIDIndividu3 = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_individu3);

export const getStateDataFromEventIDIndividu4 = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_individu4);

export const getStateDataFromEventIDIndividu5 = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_individu5);

export const getStateDataFromEventIDIndividu6 = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_individu6);

export const getStateDataFromEventIDInstanceNom = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_instance_nom);

export const getStateDataFromEventIDInstanceNomContact = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_instance_nom_contact);

export const getStateDataFromEventIDInstanceTel = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_instance_tel);

export const getStateDataFromEventIDInstanceHeure = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_instance_heure);

export const getStateDataFromEventIDInstanceSuivi = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_instance_suivi);

export const getStateDataFromEventIDInstanceSuiviNum = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_instance_suivi_num);

export const getStateMD5Users = createSelector(getAppState, (state: fromApp.State) => state.stateMD5Users);

export const getStateValidtySessionToken = createSelector(getAppState, (state: fromApp.State) => state.stateValidtySessionToken);

export const getStateBDExtPWD = createSelector(getAppState, (state: fromApp.State) => state.stateBDExtPWD);

export const getStateListinc = createSelector(getAppState, (state: fromApp.State) => state.stateListinc);

export const getStateAmoutofINC = createSelector(getAppState, (state: fromApp.State) => state.stateAmoutofINC);

export const getStateTempSelectedIDIntervention = createSelector(getAppState, (state: fromApp.State) => state.stateTempSelectedIDIntervention);

export const getStateDataFromInterventionHoraire = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromIntervention_horaire);

export const getStateDataFromInterventionTemps = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromIntervention_temps);

export const getStateDataFromInterventionLieu = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromIntervention_lieu);

export const getStateDataFromInterventionSecteur = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromIntervention_secteur);

export const getStateDataFromInterventionHeure = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromIntervention_heure);

export const getStateDataFromInterventionType = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromIntervention_type);

export const getStateDataFromInterventionResolutionDetail = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromIntervention_resolution_detail);

export const getStateDataFromInterventionDescription = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromIntervention_description);

export const getStateDataFromInterventionComments = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromIntervention_comments);

export const getStateDataFromInterventionAgent1 = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromIntervention_agent1);

export const getStateDataFromInterventionAgent2 = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromIntervention_agent2);

export const getStateDataFromInterventionDateintervention = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromIntervention_dateintervention);

export const getStateIntIDIntervention = createSelector(getAppState, (state: fromApp.State) => state.stateIntIDIntervention);

export const getStateSubmissionDate = createSelector(getAppState, (state: fromApp.State) => state.stateSubmissionDate);

export const getStateUsernameAPI = createSelector(getAppState, (state: fromApp.State) => state.stateUsernameAPI);

export const getStateInterventionsConvertComments = createSelector(getAppState, (state: fromApp.State) => state.stateInterventions_convert_comments);

export const getStateInterventionsConvertDescription = createSelector(getAppState, (state: fromApp.State) => state.stateInterventions_convert_description);

export const getStateInterventionsConvertResolution = createSelector(getAppState, (state: fromApp.State) => state.stateInterventions_convert_resolution);

export const getStateDateConvert = createSelector(getAppState, (state: fromApp.State) => state.stateDateConvert);

export const getStateIncidentsConvertDescription = createSelector(getAppState, (state: fromApp.State) => state.stateIncidents_convert_description);

export const getStateIncidentsConvertIndividu1 = createSelector(getAppState, (state: fromApp.State) => state.stateIncidents_convert_individu1);

export const getStateIncidentsConvertIndividu2 = createSelector(getAppState, (state: fromApp.State) => state.stateIncidents_convert_individu2);

export const getStateIncidentsConvertIndividu3 = createSelector(getAppState, (state: fromApp.State) => state.stateIncidents_convert_individu3);

export const getStateIncidentsConvertIndividu4 = createSelector(getAppState, (state: fromApp.State) => state.stateIncidents_convert_individu4);

export const getStateIncidentsConvertIndividu5 = createSelector(getAppState, (state: fromApp.State) => state.stateIncidents_convert_individu5);

export const getStateIncidentsConvertIndividu6 = createSelector(getAppState, (state: fromApp.State) => state.stateIncidents_convert_individu6);

export const getStateIncidentsConvertDate = createSelector(getAppState, (state: fromApp.State) => state.stateIncidents_convert_date);

export const getStateB64F1 = createSelector(getAppState, (state: fromApp.State) => state.stateB64F1);

export const getStateDashIntJan = createSelector(getAppState, (state: fromApp.State) => state.stateDash_Int_Jan);

export const getStateDashIntFeb = createSelector(getAppState, (state: fromApp.State) => state.stateDash_int_Feb);

export const getStateDashIntMar = createSelector(getAppState, (state: fromApp.State) => state.stateDash_int_Mar);

export const getStateDashIntApr = createSelector(getAppState, (state: fromApp.State) => state.stateDash_Int_Apr);

export const getStateDashIncJan = createSelector(getAppState, (state: fromApp.State) => state.stateDash_Inc_Jan);

export const getStateDashIncFeb = createSelector(getAppState, (state: fromApp.State) => state.stateDash_inc_feb);

export const getStateDashIncMar = createSelector(getAppState, (state: fromApp.State) => state.stateDash_inc_mar);

export const getStateDashIncApr = createSelector(getAppState, (state: fromApp.State) => state.stateDash_inc_apr);

export const getStateDataFromInterventionDateinterventionUTC = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromIntervention_dateinterventionUTC);

export const getStateDataFromEventIDDateutc = createSelector(getAppState, (state: fromApp.State) => state.stateDataFromEventID_dateutc);

export const getStateAVISMessage = createSelector(getAppState, (state: fromApp.State) => state.stateAVIS_Message);

export const getStateAVISTitre = createSelector(getAppState, (state: fromApp.State) => state.stateAVIS_Titre);

export const getStateAVISStatut = createSelector(getAppState, (state: fromApp.State) => state.stateAVIS_Statut);

export const getStateTEMPPRINTINTERVENTION = createSelector(getAppState, (state: fromApp.State) => state.stateTEMP_PRINTINTERVENTION);

export const getStateTEMPPRINTINTERVENTIONURL = createSelector(getAppState, (state: fromApp.State) => state.stateTEMP_PRINTINTERVENTION_URL);

export const getStateTEMPPRINTINTERVENTIONID = createSelector(getAppState, (state: fromApp.State) => state.stateTEMP_PRINTINTERVENTION_ID);

export const getStateTEMPPAYLOAD = createSelector(getAppState, (state: fromApp.State) => state.stateTEMP_PAYLOAD);

export const getStateDateTest = createSelector(getAppState, (state: fromApp.State) => state.stateDateTest);

export const getStateInterventionsPersonne1Desc = createSelector(getAppState, (state: fromApp.State) => state.stateInterventions_personne1_desc);

export const getStateInterventionsPersonne2Desc = createSelector(getAppState, (state: fromApp.State) => state.stateInterventions_personne2_desc);

export const getStateInterventionsPersonne3Desc = createSelector(getAppState, (state: fromApp.State) => state.stateInterventions_personne3_desc);

export const getStateInterventionsPersonne4Desc = createSelector(getAppState, (state: fromApp.State) => state.stateInterventions_personne4_desc);

export const getStateEventIDDateintervention = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_dateintervention);

export const getStateEventIDDateutc = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_dateutc);

export const getStateEventIDHeureintervention = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_heureintervention);

export const getStateEventIDAgent1 = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_agent1);

export const getStateEventIDAgent2 = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_agent2);

export const getStateEventIDAgentremplacant = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_agentremplacant);

export const getStateEventIDHoraire = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_horaire);

export const getStateEventIDTemperature = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_temperature);

export const getStateEventIDLieu = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_lieu);

export const getStateEventIDSecteur = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_secteur);

export const getStateEventIDNatureReferencement = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nature_referencement);

export const getStateEventIDNature311 = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nature_311);

export const getStateEventIDNature911 = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nature_911);

export const getStateEventIDNatureContactCitoyen = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nature_contact_citoyen);

export const getStateEventIDNatureContactPopulation = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nature_contact_population);

export const getStateEventIDNatureContactOrganisme = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nature_contact_organisme);

export const getStateEventIDNatureIntervention = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nature_intervention);

export const getStateEventIDNatureDechet = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nature_dechet);

export const getStateEventIDNatureMaterielDonne = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nature_materiel_donne);

export const getStateEventIDNatureMaterielRecupere = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nature_materiel_recupere);

export const getStateEventIDNatureAppelrecu = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nature_appelrecu);

export const getStateEventIDNatureNuisible = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nature_nuisible);

export const getStateEventIDNaturePremiersoins = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nature_premiersoins);

export const getStateEventIDNatureObservation = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nature_observation);

export const getStateEventIDNatureAutres = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nature_autres);

export const getStateEventIDDescriptionSituation = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_description_situation);

export const getStateEventIDNbpersonnes = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nbpersonnes);

export const getStateEventIDNbvehicules = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_nbvehicules);

export const getStateEventIDPersonne1Statut = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_personne1_statut);

export const getStateEventIDPersonne1Nom = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_personne1_nom);

export const getStateEventIDPersonne1Matricule = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_personne1_matricule);

export const getStateEventIDPersonne1Description = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_personne1_description);

export const getStateEventIDPersonne2Statut = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_personne2_statut);

export const getStateEventIDPersonne2Nom = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_personne2_nom);

export const getStateEventIDPersonne2Matricule = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_personne2_matricule);

export const getStateEventIDPersonne2Description = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_personne2_description);

export const getStateEventIDPersonne3Statut = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_personne3_statut);

export const getStateEventIDPersonne3Nom = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_personne3_nom);

export const getStateEventIDPersonne3Matricule = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_personne3_matricule);

export const getStateEventIDPersonne3Description = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_personne3_description);

export const getStateEventIDPersonne4Statut = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_personne4_statut);

export const getStateEventIDPersonne4Nom = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_personne4_nom);

export const getStateEventIDPersonne4Matricule = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_personne4_matricule);

export const getStateEventIDPersonne4Description = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_personne4_description);

export const getStateEventIDVehicule1Type = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_vehicule1_type);

export const getStateEventIDVehicule1Plaque = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_vehicule1_plaque);

export const getStateEventIDVehicule1Modele = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_vehicule1_modele);

export const getStateEventIDVehicule1Couleur = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_vehicule1_couleur);

export const getStateEventIDVehicule1Signes = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_vehicule1_signes);

export const getStateEventIDVehicule2Type = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_vehicule2_type);

export const getStateEventIDVehicule2Plaque = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_vehicule2_plaque);

export const getStateEventIDVehicule2Modele = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_vehicule2_modele);

export const getStateEventIDVehicule2Couleur = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_vehicule2_couleur);

export const getStateEventIDVehicule2Signes = createSelector(getAppState, (state: fromApp.State) => state.stateEventID_vehicule2_signes);

export const getStateAmountofInterventions = createSelector(getAppState, (state: fromApp.State) => state.stateAmountofInterventions);

export const getStateListInterventions = createSelector(getAppState, (state: fromApp.State) => state.stateListInterventions);

export const getStateTempSelectedIntervention = createSelector(getAppState, (state: fromApp.State) => state.stateTempSelectedIntervention);

export const getStateListinc2 = createSelector(getAppState, (state: fromApp.State) => state.stateListinc2);

export const getStateListconcat = createSelector(getAppState, (state: fromApp.State) => state.stateListconcat);

export const getStateAMoutofINTCONCAT = createSelector(getAppState, (state: fromApp.State) => state.stateAMoutofINTCONCAT);

export const getStateListInterventions2 = createSelector(getAppState, (state: fromApp.State) => state.stateListInterventions2);

export const getStateListconcat2 = createSelector(getAppState, (state: fromApp.State) => state.stateListconcat2);

export const getStateListInterventions3 = createSelector(getAppState, (state: fromApp.State) => state.stateListInterventions3);

export const getStateListInterventions4 = createSelector(getAppState, (state: fromApp.State) => state.stateListInterventions4);

export const getStateListInterventions5 = createSelector(getAppState, (state: fromApp.State) => state.stateListInterventions5);

export const getStateListinterventions6 = createSelector(getAppState, (state: fromApp.State) => state.stateListinterventions6);

export const getStateListInterventions7 = createSelector(getAppState, (state: fromApp.State) => state.stateListInterventions7);

export const getStateListMembresValid = createSelector(getAppState, (state: fromApp.State) => state.stateListMembresValid);

export const getStateINTIDVisites = createSelector(getAppState, (state: fromApp.State) => state.stateINTIDVisites);

export const getStateTodayDate = createSelector(getAppState, (state: fromApp.State) => state.stateTodayDate);

export const getStateTempMembreID = createSelector(getAppState, (state: fromApp.State) => state.stateTempMembreID);

export const getStateListIntervention8 = createSelector(getAppState, (state: fromApp.State) => state.stateListIntervention8);

export const getStateListInterventions9 = createSelector(getAppState, (state: fromApp.State) => state.stateListInterventions9);

export const getStateListInterventions10 = createSelector(getAppState, (state: fromApp.State) => state.stateListInterventions10);

export const getUiUsername = createSelector(getAppState, (state: fromApp.State) => state.uiUsername);

export const getUiSecret = createSelector(getAppState, (state: fromApp.State) => state.uiSecret);

export const getUiTabs2 = createSelector(getAppState, (state: fromApp.State) => state.uiTabs2);

export const getUiSmartTable = createSelector(getAppState, (state: fromApp.State) => state.uiSmartTable);

export const getUiTabs = createSelector(getAppState, (state: fromApp.State) => state.uiTabs);

export const getUiTextArea = createSelector(getAppState, (state: fromApp.State) => state.uiTextArea);

export const getUiTextArea1 = createSelector(getAppState, (state: fromApp.State) => state.uiTextArea1);

export const getUiTextArea2 = createSelector(getAppState, (state: fromApp.State) => state.uiTextArea2);

export const getUiTextArea3 = createSelector(getAppState, (state: fromApp.State) => state.uiTextArea3);

export const getUiTextArea4 = createSelector(getAppState, (state: fromApp.State) => state.uiTextArea4);

export const getUiTextArea41 = createSelector(getAppState, (state: fromApp.State) => state.uiTextArea41);

export const getUiTextArea42 = createSelector(getAppState, (state: fromApp.State) => state.uiTextArea42);

export const getUiTextArea5 = createSelector(getAppState, (state: fromApp.State) => state.uiTextArea5);

export const getUiTextArea6 = createSelector(getAppState, (state: fromApp.State) => state.uiTextArea6);

export const getUiTextArea7 = createSelector(getAppState, (state: fromApp.State) => state.uiTextArea7);

export const getUiSmartTableIAInter = createSelector(getAppState, (state: fromApp.State) => state.uiSmartTableIA_Inter);

export const getUiTableInterventions = createSelector(getAppState, (state: fromApp.State) => state.uiTableInterventions);

export const getUiDateIntervention = createSelector(getAppState, (state: fromApp.State) => state.uiDateIntervention);

export const getUiHeureIntervention = createSelector(getAppState, (state: fromApp.State) => state.uiHeureIntervention);

export const getUiNomAgent1 = createSelector(getAppState, (state: fromApp.State) => state.uiNomAgent1);

export const getUiNomAgent2 = createSelector(getAppState, (state: fromApp.State) => state.uiNomAgent2);

export const getUiNomAgentRemplacant = createSelector(getAppState, (state: fromApp.State) => state.uiNomAgentRemplacant);

export const getUiHoraire = createSelector(getAppState, (state: fromApp.State) => state.uiHoraire);

export const getUiTemperature = createSelector(getAppState, (state: fromApp.State) => state.uiTemperature);

export const getUiLieuIntervention = createSelector(getAppState, (state: fromApp.State) => state.uiLieuIntervention);

export const getUiNumSecteur = createSelector(getAppState, (state: fromApp.State) => state.uiNumSecteur);

export const getUiCheckReferencement = createSelector(getAppState, (state: fromApp.State) => state.uiCheck_Referencement);

export const getUiCheckAppel311 = createSelector(getAppState, (state: fromApp.State) => state.uiCheck_Appel311);

export const getUiCheckAppel911 = createSelector(getAppState, (state: fromApp.State) => state.uiCheck_Appel911);

export const getUiCheckContactCitoyens = createSelector(getAppState, (state: fromApp.State) => state.uiCheck_ContactCitoyens);

export const getUiCheckContactMarginalisees = createSelector(getAppState, (state: fromApp.State) => state.uiCheck_ContactMarginalisees);

export const getUiCheckContactOrganisme = createSelector(getAppState, (state: fromApp.State) => state.uiCheck_ContactOrganisme);

export const getUiCheckInterventionCommercant = createSelector(getAppState, (state: fromApp.State) => state.uiCheck_InterventionCommercant);

export const getUiCheckRamassageDechet = createSelector(getAppState, (state: fromApp.State) => state.uiCheck_RamassageDechet);

export const getUiCheckMaterielDonne = createSelector(getAppState, (state: fromApp.State) => state.uiCheck_MaterielDonne);

export const getUiCheckMaterielRecupere = createSelector(getAppState, (state: fromApp.State) => state.uiCheck_MaterielRecupere);

export const getUiCheckAppelRecu = createSelector(getAppState, (state: fromApp.State) => state.uiCheck_AppelRecu);

export const getUiCheckInterventionNuisible = createSelector(getAppState, (state: fromApp.State) => state.uiCheck_InterventionNuisible);

export const getUiCheckPremierssoins = createSelector(getAppState, (state: fromApp.State) => state.uiCheck_Premierssoins);

export const getUiCheckObservation = createSelector(getAppState, (state: fromApp.State) => state.uiCheck_Observation);

export const getUiCheckAutres = createSelector(getAppState, (state: fromApp.State) => state.uiCheck_Autres);

export const getUiDescriptionIntervention = createSelector(getAppState, (state: fromApp.State) => state.uiDescriptionIntervention);

export const getUiCDateIntervention = createSelector(getAppState, (state: fromApp.State) => state.uiC_DateIntervention);

export const getUiCHeureIntervention = createSelector(getAppState, (state: fromApp.State) => state.uiC_HeureIntervention);

export const getUiCNomAgent1 = createSelector(getAppState, (state: fromApp.State) => state.uiC_NomAgent1);

export const getUiCNomAgent2 = createSelector(getAppState, (state: fromApp.State) => state.uiC_NomAgent2);

export const getUiCNomRemplacant = createSelector(getAppState, (state: fromApp.State) => state.uiC_NomRemplacant);

export const getUiCHoraire = createSelector(getAppState, (state: fromApp.State) => state.uiC_Horaire);

export const getUiCTemperature = createSelector(getAppState, (state: fromApp.State) => state.uiC_Temperature);

export const getUiCLieu = createSelector(getAppState, (state: fromApp.State) => state.uiC_Lieu);

export const getUiCSecteur = createSelector(getAppState, (state: fromApp.State) => state.uiC_Secteur);

export const getUiCCheckReferencement = createSelector(getAppState, (state: fromApp.State) => state.uiC_Check_Referencement);

export const getUiCCheck311 = createSelector(getAppState, (state: fromApp.State) => state.uiC_Check_311);

export const getUiCCheck911 = createSelector(getAppState, (state: fromApp.State) => state.uiC_Check_911);

export const getUiCCheckContactCitoyen = createSelector(getAppState, (state: fromApp.State) => state.uiC_Check_ContactCitoyen);

export const getUiCCheckContactmarginalisee = createSelector(getAppState, (state: fromApp.State) => state.uiC_Check_contactmarginalisee);

export const getUiCCheckContactOrganisme = createSelector(getAppState, (state: fromApp.State) => state.uiC_Check_ContactOrganisme);

export const getUiCCheckInterventionCommercant = createSelector(getAppState, (state: fromApp.State) => state.uiC_Check_InterventionCommercant);

export const getUiCCheckRamassagedechet = createSelector(getAppState, (state: fromApp.State) => state.uiC_Check_ramassagedechet);

export const getUiCCheckMaterieldonnee = createSelector(getAppState, (state: fromApp.State) => state.uiC_Check_materieldonnee);

export const getUiCCheckMaterielRecupere = createSelector(getAppState, (state: fromApp.State) => state.uiC_Check_materielRecupere);

export const getUiCCheckAppelRecu = createSelector(getAppState, (state: fromApp.State) => state.uiC_Check_AppelRecu);

export const getUiCCheckNuisible = createSelector(getAppState, (state: fromApp.State) => state.uiC_Check_Nuisible);

export const getUiCCheckPremierssoins = createSelector(getAppState, (state: fromApp.State) => state.uiC_Check_premierssoins);

export const getUiCCheckObservation = createSelector(getAppState, (state: fromApp.State) => state.uiC_Check_Observation);

export const getUiCCheckAutres = createSelector(getAppState, (state: fromApp.State) => state.uiC_Check_autres);

export const getUiCDescriptionSituation = createSelector(getAppState, (state: fromApp.State) => state.uiC_DescriptionSituation);

export const getUiNomAgentSupport = createSelector(getAppState, (state: fromApp.State) => state.uiNomAgentSupport);

export const getUiSupportURGENT = createSelector(getAppState, (state: fromApp.State) => state.uiSupportURGENT);

export const getUiSUpportMesage = createSelector(getAppState, (state: fromApp.State) => state.uiSUpportMesage);

export const getUiTableVisit = createSelector(getAppState, (state: fromApp.State) => state.uiTableVisit);

