// @ts-nocheck
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import * as fromApp from '../../../state/app.reducer';
import * as AppActions from '../../../state/app.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {

  spacing = {
        	margins: '',
        	paddings: '16px 16px 16px 16px'
        };

  size = {
        	xl: {
          	width: 'auto',
          	height: '60px',
          	minWidth: '0',
          	minHeight: '0',
          	maxWidth: 'none',
          	maxHeight: 'none'
          },
        	sm: {
          	width: 'auto',
          	height: '60px',
          	minWidth: '0',
          	minHeight: '0',
          	maxWidth: 'none',
          	maxHeight: 'none'
          }
        };

  backgroundColor = {
        	xl: 'var(--color-info-default)',
        	lg: 'var(--color-info-default)',
        	xs: 'var(--color-info-default)'
        };

  flexContainer = {
        	flexDirection: 'row',
        	alignItems: 'center',
        	justifyContent: 'center',
        	flexWrap: 'wrap',
        	alignContent: 'center'
        };

  size1 = {
        	width: '100%',
        	height: 'auto',
        	minWidth: '0',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };

  overflow = {
        	overflowX: 'visible',
        	overflowY: 'visible'
        };

  flexContainer1 = {
        	xl: {
          	flexDirection: 'row',
          	alignItems: 'center',
          	justifyContent: 'space-between',
          	flexWrap: 'wrap',
          	alignContent: 'center'
          },
        	xs: {
          	flexDirection: 'row',
          	alignItems: 'flex-start',
          	justifyContent: 'space-between',
          	flexWrap: 'wrap',
          	alignContent: 'flex-start'
          }
        };

  size2 = {
        	width: '100%',
        	height: 'auto',
        	minWidth: '0',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };

  overflow1 = {
        	overflowX: 'visible',
        	overflowY: 'visible'
        };

  flexContainer2 = {
        	flexDirection: 'row',
        	alignItems: 'flex-start',
        	justifyContent: 'center',
        	flexWrap: 'wrap',
        	alignContent: 'flex-start'
        };

  flex = {
        	xl: {
          	flex: '0 0 auto',
          	alignSelf: 'auto',
          	order: 0
          },
        	sm: {
          	flex: '1 1 0%',
          	alignSelf: 'auto',
          	order: 0
          }
        };

  spacing1 = {
        	margins: '',
        	paddings: ''
        };

  size3 = {
        	xl: {
          	width: '386px',
          	height: '32%',
          	minWidth: '0',
          	minHeight: '0',
          	maxWidth: 'none',
          	maxHeight: 'none'
          },
        	sm: {
          	width: 'auto',
          	height: 'auto',
          	minWidth: '0',
          	minHeight: '0',
          	maxWidth: 'none',
          	maxHeight: 'none'
          },
        	xs: {
          	width: '146px',
          	height: 'auto',
          	minWidth: '0',
          	minHeight: '0',
          	maxWidth: 'none',
          	maxHeight: 'none'
          }
        };

  overflow2 = {
        	overflowX: 'visible',
        	overflowY: 'visible'
        };

  size4 = {
        	custom: false,
        	customValue: '1.25rem',
        	predefinedValue: 'giant'
        };

  visible = {
        	xl: true,
        	xs: true
        };

  size5 = {
        	xl: {
          	width: 'auto',
          	height: '48px',
          	minWidth: '0',
          	minHeight: '0',
          	maxWidth: 'none',
          	maxHeight: 'none'
          },
        	lg: {
          	width: '40%',
          	height: '48px',
          	minWidth: '0',
          	minHeight: '0',
          	maxWidth: 'none',
          	maxHeight: 'none'
          },
        	xs: {
          	width: '100%',
          	height: '48px',
          	minWidth: '0',
          	minHeight: '0',
          	maxWidth: 'none',
          	maxHeight: 'none'
          }
        };

  src = {
        	xl: 'https://sauibakeryprod.blob.core.windows.net/assets-container/assets/e8thp56mtg/images/XzMyXdI1HD',
        	lg: 'https://sauibakeryprod.blob.core.windows.net/assets-container/assets/e8thp56mtg/images/ONfWNgY07t',
        	xs: 'https://sauibakeryprod.blob.core.windows.net/assets-container/assets/e8thp56mtg/images/w9Axky51r3'
        };

  fit = {
        	xl: 'contain',
        	lg: 'contain',
        	xs: 'contain'
        };

  flexContainer3 = {
        	flexDirection: 'row',
        	alignItems: 'center',
        	justifyContent: 'flex-end',
        	flexWrap: 'wrap',
        	alignContent: 'center'
        };

  flex1 = {
        	xl: {
          	flex: '0 1 auto',
          	alignSelf: 'auto',
          	order: 0
          },
        	sm: {
          	flex: '1 1 0%',
          	alignSelf: 'auto',
          	order: 0
          },
        	xs: {
          	flex: '0 1 0%',
          	alignSelf: 'auto',
          	order: 0
          }
        };

  spacing2 = {
        	margins: '',
        	paddings: ''
        };

  size6 = {
        	xl: {
          	width: '30%',
          	height: 'auto',
          	minWidth: '0',
          	minHeight: '0',
          	maxWidth: 'none',
          	maxHeight: 'none'
          },
        	sm: {
          	width: 'auto',
          	height: 'auto',
          	minWidth: '0',
          	minHeight: '0',
          	maxWidth: 'none',
          	maxHeight: 'none'
          },
        	xs: {
          	width: '85px',
          	height: 'auto',
          	minWidth: '0',
          	minHeight: '0',
          	maxWidth: 'none',
          	maxHeight: 'none'
          }
        };

  overflow3 = {
        	overflowX: 'visible',
        	overflowY: 'visible'
        };

  spacing3 = {
        	xl: {
          	margins: '2% 0 0 0',
          	paddings: ''
          },
        	sm: {
          	margins: '0 0 0 0',
          	paddings: ''
          }
        };

  size7 = {
        	width: '100%',
        	height: 'auto',
        	minWidth: 'auto',
        	minHeight: '0',
        	maxWidth: 'none',
        	maxHeight: 'none'
        };
  constructor(private store: Store<fromApp.State>) {}


  spaceInit ($event) {
    this.store.dispatch(AppActions.getAvis({ param: $event }));
  }

  buttonButtonClick ($event) {
    this.store.dispatch(AppActions.getBackHome({ param: $event }));
  }
  ngOnInit(): void {}
}
