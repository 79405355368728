import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './shared/layout/layout.component';
import { SeConnecterComponent } from './pages/se-connecter/se-connecter.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: 'home2', loadChildren: () => import('./pages/accueil/accueil.module').then(m => m.AccueilModule) },
      { path: 'Tableau-de-bord', loadChildren: () => import('./pages/tableau-de-bord/tableau-de-bord.module').then(m => m.TableauDeBordModule) },
      { path: 'archives', loadChildren: () => import('./pages/accueil-des-archives/accueil-des-archives.module').then(m => m.AccueilDesArchivesModule) },
      { path: 'interventions', loadChildren: () => import('./pages/interventions/interventions.module').then(m => m.InterventionsModule) },
      { path: 'Support', loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule) },
      { path: 'visite', loadChildren: () => import('./pages/visite-des-commercants/visite-des-commercants.module').then(m => m.VisiteDesCommercantsModule) },
      { path: '', redirectTo: '/login', pathMatch: 'full' },
],
  },
  { path: 'login', component: SeConnecterComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
